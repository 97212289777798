jQuery(document).ready(function ($) {
  sticky_header();

  // Application Form Gender Value Hide Fields
  $("#reg-gender").on('change', function() {
    $(".input-wrap").removeClass('hidden-field');
    $("input").removeAttr('tabindex');
    if(this.value == 'male'){
      $("#reg-bust, #reg-dress, #reg-hip").attr('tabindex', -1).parent().addClass('hidden-field');
    }
    else{
      $("#reg-chest, #reg-inleg").attr('tabindex', -1).parent().addClass('hidden-field');
    }

  });

  // Application Work Nude/Semi Nude auto toggling
  $(document).on('change', '#reg-nude', function(){
    if(this.checked){
      if(!$("#reg-semi-nude").is(':checked')){
        $("#reg-semi-nude").click();
      }
    }
  });
  $(document).on('change', '#reg-semi-nude', function(){
    if(!this.checked){
      if($("#reg-nude").is(':checked')){
        $("#reg-nude").click();
      }
    }
  });
  

  // Member Back Button
  $("#member-back").click(function(){
    window.history.back();
    $(this).addClass('done');
  });

  // Register Form Height
  $("#reg-height-ft").keyup(function(){
    $("#reg-height-in").focus();
  });

  // Input type=file handler
  $(".hj-upload-button").click(function(){
    var button = $(this).attr("data-upload");
    $("input[id="+button+"]").click();
  });

  $("input[type=file]").on('change', function(){
    var input = $(this).attr("name");
    var file = $(this).context.files[0].name;
    $(".hj-upload-button[data-upload="+input+"]").addClass('file-added').text(file);

    if(!file){
      $(".hj-upload-button[data-upload="+input+"]").removeClass('file-added').text('Upload Photo');
    }
  });

  // Registration Form Section Nav

  // if($(".page-template-register").length > 0){

  //   $(".register-form fieldset").each(function(){
  //     var attr = $(this).attr("data-section");
  //     var title = $(this).children("legend").text();
  //     $(".form-nav").append('<li class="'+attr+'">'+title+'</li>');
  //   });

  //   $(".form-nav li").click(function(){
  //     var section = $(this).attr('class');
  //    $([document.documentElement, document.body]).animate({
  //      scrollTop: $('.section-wrap[data-section='+section+']').offset().top - 150
  //  }, 1500);
  //   });

  // }

  // Registration form hide fieldsets on init

  $("#confirm-terms").change(function () {
    if (this.checked) {
      $(".section-wrap").removeClass("hidden");
      $(".form-nav").addClass("active");
    }
  });

  // Highlight first pagination link of member sector on first pag page only
  if ($(".category").length > 0 || $(".page-template-artistes").length > 0) {
    if (!document.location.search.length) {
      $("span.page-numbers:nth-of-type(1)").addClass('current');
    }
  }

  //
  if ($(".page-template-register").length > 0) {

    if (window.location.search.includes('login')) {
      $(".detail-tabs .detail-tab[data-tab=login]").addClass("active-tab");
      $(".login-pad[data-tabinfo=login]").addClass("active-section");
    }
    if (window.location.search.includes('register')) {
      $(".detail-tabs .detail-tab[data-tab=register]").addClass("active-tab");
      $(".login-pad[data-tabinfo=register]").addClass("active-section");
    }

  }

  $(".input-wrap i").click(function () {
    var inputType = $(this).siblings("input").attr('type');
    if (inputType == 'password') {
      $(this).siblings("input").attr('type', 'text');
    } else {
      $(this).siblings("input").attr('type', 'password');
    }
    $(this).parent().children('i').toggleClass('hidden-field');
  });

  /* Project Sliders */

  // Vars For Reading Ease
  var sliderShowsBreakdown = [{
    breakpoint: 1024,
    settings: {
      slidesToShow: 5,
      slidesToScroll: 1
    }
  }, {
    breakpoint: 960,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 1
    }
  }, {
    breakpoint: 768,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1
    }
  }, {
    breakpoint: 480,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1
    }
  }];
  var sliderGalleryBreakdown = [{
    breakpoint: 1024,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3
    }
  }, {
    breakpoint: 768,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2
    }
  }, {
    breakpoint: 480,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1
    }
  }];
  var sliderArrowPrev = "<i class='fa fa-angle-left slide-prev'></i>";
  var sliderArrowNext = "<i class='fa fa-angle-right slide-next'></i>";

  // Slider Objects
  // Args - ["FUNCTION_HOOK", "SLIDE_NAME (str)", AUTOPLAY (bool), DOTS (bool), ARROWS (bool), PREV_ARROW (str), NEXT_ARROW (str), INFINITE (bool), SLIDES_TO_SHOW (int), SLIDES_TO_SCROLL (int), SPEED (int), AUTOPLAY_SPEED (int), PAUSE_ON_HOVER (bool), RESPONSIVE(obj) ];
  var themeSliders = [
    ["slickGallery", "gallery-slide-container", false, false, true, sliderArrowPrev, sliderArrowNext, true, 3, 3, 300, 3000, true, sliderGalleryBreakdown],
    [false, "featured-shows-wrap", true, false, true, sliderArrowPrev, sliderArrowNext, true, 3, 1, 300, 3000, true, sliderShowsBreakdown],
    [false, "pp-slider", true, false, false, "", "", true, 1, 1, 300, 3000, false, false],
    [false, "page-banner-slider", true, false, false, "", "", true, 1, 1, 500, 5000, false, false]
  ];

  // Slider Loop and Output
  themeSliders.forEach(function (slideAttribute) {

    // Execute Slider Code Immediately
    if (slideAttribute[0] === false) {
      $("." + slideAttribute[1]).slick({
        autoplay: slideAttribute[2],
        dots: slideAttribute[3],
        arrows: slideAttribute[4],
        prevArrow: slideAttribute[5],
        nextArrow: slideAttribute[6],
        infinite: slideAttribute[7],
        slidesToShow: slideAttribute[8],
        slidesToScroll: slideAttribute[9],
        speed: slideAttribute[10],
        autoplaySpeed: slideAttribute[11],
        pauseOnHover: slideAttribute[12],
        responsive: slideAttribute[13]
      });
    }
    // Assign Slider To Function
    else {
      slideFunction = {};
      slideFunction[slideAttribute[0]] = function () {
        $("." + slideAttribute[1]).slick({
          autoplay: slideAttribute[2],
          dots: slideAttribute[3],
          arrows: slideAttribute[4],
          prevArrow: slideAttribute[5],
          nextArrow: slideAttribute[6],
          infinite: slideAttribute[7],
          slidesToShow: slideAttribute[8],
          slidesToScroll: slideAttribute[9],
          speed: slideAttribute[10],
          autoplaySpeed: slideAttribute[11],
          pauseOnHover: slideAttribute[12],
          responsive: slideAttribute[13]
        });
      };
    }
  });

  // Get video on click

  $('.video-wrap .video-box .image-overlay').click(function () {
    var vidref = $(this).parent().parent().attr('data-vidRef');
    if (vidref) {
      var vidOutput = "<iframe src='https://www.youtube.com/embed/" + vidref + "?autoplay=1&rel=0' frameborder='0' allow='encrypted-media' allowfullscreen></iframe>";
      $(this).parent('.media-wrap').html(vidOutput).css('background-image', 'none');
    }
  });

  // FAQs

  // $(".faq").each(function () {
  //   var faq = $(this);
  //   var tHeight = faq.outerHeight();
  //   var qHeight = faq.children(".question").outerHeight();
  //   faq.attr("theight", tHeight).attr("qheight", qHeight);
  //   faq.height(tHeight);
  //   faq.not(".active-faq").height(qHeight);
  // });

  // // Toggle

  // $(".faq").click(function () {
  //   var clicked = $(this);

  //   // Make sure clicked isn't already active
  //   if (!clicked.hasClass("active-faq")) {

  //     // Reset current active FAQ
  //     currActvFaq = $(".active-faq");
  //     $(".active-faq").height(currActvFaq.attr("qheight")).removeClass("active-faq");

  //     // Apply active to new FAQ
  //     clicked.addClass("active-faq").height(clicked.attr("theight"));
  //   }
  // });

  // Shortlist Buttons

  $('#print-shortlist').click(function () {
    window.print();
  });

  // Shortlist Email Button Modal Window

  $("#email-shortlist").click(function () {
    $(".shortlist-email-modal").addClass("active-modal");
    $(".shortlist-modal-overlay").addClass("active-overlay");
  });

  $(".modal-close").click(function () {
    $(".shortlist-email-modal").removeClass("active-modal");
    $(".shortlist-modal-overlay").removeClass("active-overlay");
    $(".shortlist-email-modal").removeClass("form-success form-errors");
    $(".shortlist-email-response").remove();
  });

  // Fixed Header

  var headerHeight = $("header").outerHeight();
  $(".hero-banner").css("padding-top", headerHeight);

  $(window).scroll(function () {
    sticky_header();
  });

  function sticky_header() {
    var headerHeight = $("header").outerHeight();
    if ($(window).scrollTop() > headerHeight) {
      $("header").addClass("sticky-header");
    } else {
      $("header").removeClass("sticky-header");
    }
  }

  // Search Functionality

  $(".search-toggle").click(function () {
    $(".search-wrap").toggleClass("active-search");
    $(".member-search-results").removeClass("hidden-search");
  });

  $(".search-close").click(function () {
    $(".search-wrap").removeClass("active-search");
    $(".member-search-results").addClass('hidden-search');
  });

  // Search additional options toggle

  $(".advanced-search-wrap p").click(function () {
    $(this).parent().toggleClass("show-advanced");
  });

  // Search results close

  $(document).on('click', '.member-search-results .search-results-close', function () {
    $(".member-search-results").remove();
    $(".search-wrap").removeClass("active-search");
    $("body").removeClass("no-scroll");
  });

  // Share Functionality

  $(".share-toggle").click(function () {
    $(".share").toggleClass('active-share');
  });

  // Single Member Tabs

  $(".detail-tab").click(function () {
    var clicked = $(this);
    var tab = clicked.attr("data-tab");

    // Handle tab and section active/inactive

    if (!clicked.hasClass("active-tab")) {
      $(".detail-tab").removeClass("active-tab");
      clicked.attr('data-arr', tab).addClass("active-tab");
      $(".detail-info").removeClass("active-section");
      $(".detail-info[data-tabinfo=" + tab + "]").addClass("active-section");
    }

    // Initialize slick when media section is rendered on tab click, otherwise images dont load due to section being hidden

    if ($(".detail-tab").attr("data-tabinfo", "media")) {
      if (!$(".gallery-slide-container").hasClass("slick-initialized")) {
        slideFunction.slickGallery();
      }
    } else {

    }

    // Scroll to section at mobile

    if ($(window).width() < 650) {
      $("html,body").animate({
        scrollTop: $(".member-details").offset().top - 120
      }, 1000);
    }

  });

  // Responsive nav
  var nav = $('.main-nav');
  $('.mobile-burger').click(function () {
    var el = $(this);
    if (el.hasClass('open')) {
      el.removeClass('open');
      $('.main-nav > ul > li').removeClass('animate');
      nav.css({
        'opacity': '0',
        'visibility': 'hidden',
        '-webkit-transform': 'scaleX(0)',
        '-ms-transform': 'scaleX(0)',
        'transform': 'scaleX(0)',
        'transition': '300ms ease-in-out all'
      });
    } else {
      el.addClass('open');
      $('.main-nav > ul > li').addClass('animate');
      nav.css({
        'opacity': '1',
        'visibility': 'visible',
        '-webkit-transform': 'scaleX(1)',
        '-ms-transform': 'scaleX(1)',
        'transform': 'scaleX(1)',
        'transition': '300ms ease-in-out all'
      });
    }
  });
  $(window).on('resize', function () {
    var mqmin = window.matchMedia("(min-width: 1024px)");
    if (mqmin.matches) {
      nav.attr('style', '');
      $('.mobile-burger').removeClass('open');
    }
  });

  function navTriggers() {
    $(".menu-item-has-children").click(function (e) {
      e.stopPropagation();
      $(this)
        .toggleClass("active")
        .children(".sub-menu")
        .toggleClass("open");
    });
    $('.main-nav a').click(function (e) {
      e.stopPropagation();
    });
  }
  navTriggers();



  // Anchor scroll

  $(".anchor").click(function (e) {
    e.preventDefault();
    e.stopPropagation();
    var $href = $(this).attr("href");
    $("body, html")
      .stop()
      .animate({
          scrollTop: $($href).offset().top
        },
        800
      );
    return false;
  });

  // Lazyload Effect
  var viewport = $(window),
    setVisible = function (e) {
      var viewportBottom = viewport.scrollTop() + viewport.height();
      $(".lazy").each(function () {
        var self = $(this);
        var top = self.offset().top;
        var mqmax = window.matchMedia("(max-width: 1024px)");
        var gapHeight = 100;
        if (mqmax.matches) {
          gapHeight = 40;
        }
        if (top <= viewportBottom - gapHeight) {
          self.addClass("lazy-loaded");
        }
      });
    };
  viewport.scroll(setVisible);
  setVisible();

  // Converts hex code to rgb value

  function hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  // Category page colour schemes

  if ($("body").data('clrschm')) {
    var catClr = $("body").data('clrschm');

    // Header border
    $("header").css("border-color", catClr);
    // Active header nav item
    $(".nav-wrapper .nav-container #menu-primary-menu li.current-menu-item").css("border-color", catClr);
    // Header nav item hovers
    $(".nav-wrapper .nav-container #menu-primary-menu li").mouseover(function () {
      $(this).css("border-color", catClr);
    });
    $(".nav-wrapper .nav-container #menu-primary-menu li").mouseleave(function () {
      if (!$(this).hasClass("current-menu-item")) {
        $(this).css("border-color", "transparent");
      }
    });
    // Shortlist Button
    $("header .shortlist-button").css("background", catClr);
    $("header .shortlist-button").mouseover(function () {
      $(this).css("border-color", catClr);
      $(this).css({
        "background": "transparent",
        "color": catClr
      });
    });
    $("header .shortlist-button").mouseleave(function () {
      $(this).css("border-color", "transparent");
      $(this).css({
        "background": catClr,
        "color": "#000"
      });
    });
    // Search Button
    $("header .search-toggle").css("background", catClr);
    // Login Button
    $("header .login").css("background", catClr);
    // CTA
    $(".sector-cta").css("background", catClr);
    // Age Filters
    $(".active-filter").css("background", catClr).children('i').css('border-top-color', catClr);
    // Card Overlay
    var overlayRGB = hexToRgb(catClr);
    $(".member-card .card-inner .card-overlay").css("background", "rgba(" + overlayRGB.r + "," + overlayRGB.g + "," + overlayRGB.b + ", 0.4" );
    $(".page-numbers.current").css("color", catClr);
    // Back To Top Button
    $(".back-to-top a").css("background", catClr);
    // Footer
    $("footer").css("border-color", catClr);
    // Footer Contact Info Icons
    $("footer i.fa").css("background", catClr);
  }

  if ($(window).width() < 768) {

    $(".member-filters h3").click(function () {
      $(this).parent(".member-filters").toggleClass("active-filter");
    });
    $(".sector-filters h3").click(function () {
      $(this).parent(".sector-filters").toggleClass("active-filter");
    });

  }

  // Shortlist save as PDF button
  $('#save-shortlist').on('click', function () {

    $(".shortlist-overlay").addClass('toggled');

    setTimeout(function(){

      $("<style class='pdf-styling'>"+
      ".page-title-wrap{display: none !important;}header{display: none !important;}.row{padding:0!important;position:static!important;overflow:visible!important}"+""+"#shortlist-container>*{padding:0!important;position:static!important;overflow:visible!important}"+""+"#shortlist-container{margin:0!important;overflow:visible!important;pointer-events:none!important}*{margin:0!important;padding:0!important;position:static!important;overflow:visible!important}#shortlist-remove,.nav-wrapper,.page-title-wrap,.search-wrap,.shortlist-buttons,.shortlist-overlay,footer{display:none!important}header{display:none}.logo{text-decoration:none!important}.logo small{font-size:14px!important;display:inline-block!important;margin-left:20px!important}#shortlist-container{display:table;width:100%;vertical-align:top}.member-card{display:table-row;width:auto;height:255px;clear:both;padding:0;vertical-align:top;overflow:hidden}.card-inner{display:block;margin:0!important;padding:0!important;text-align:left}.card-inner img{display:inline-block;vertical-align:top!important;margin:0!important;padding:0!important;border:solid 1px #000;max-height:200px;max-width:200px}h3{border-top:solid 10px transparent;border-bottom:solid 10px transparent;text-decoration:none}.measurements{display:table-cell;width:300px}.measurements .measurements{line-height:100px;border-left:solid 0 transparent}.measurements p{line-height:30px}"+
      "</style>").appendTo("#shortlist-container");

      var element = document.getElementById('shortlist-container');

      var d = new Date();
      var dateParts = { dY : d.getFullYear(), dM : (d.getMonth() + 1), dD : d.getDate() };

      if(dateParts.dD < 10){
        dateParts.dD = '0' + d.getDate();
      }

      if(dateParts.dM < 10){
        dateParts.dM = '0' + (d.getMonth() + 1);
      }

      var fileName = 'mint-shortlist-' + dateParts.dD + '-' + dateParts.dM + '-' + dateParts.dY + '.pdf';

      var opt = {
        margin: 0,
        filename: fileName,
        image: { type: 'jpeg', quality: 0.98 },
        pagebreak: {mode: 'avoid-all'},
        html2canvas: { scale: 1 },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' }
      };

      html2pdf(element, opt);
      //html2pdf().set(opt).from(element).save();

    }, 1000);

    setTimeout( function(){
      $('.pdf-styling').remove();
    }, 1500);
    setTimeout( function(){
      $('.shortlist-overlay').removeClass('toggled');
    }, 2000);

  });

});